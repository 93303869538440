import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import SSMSeriesImage from "../../components/logicom-images/SSMSeries.js"
import SESeriesImage from "../../components/logicom-images/SESeries.js"
import WalkInSeriesImage from "../../components/logicom-images/WalkinSeries.js"

const TempHumid = () => (
    <Layout>
      <SEO title="Temperature and Humidity Test" />
      <Container>
        <Row>
            <Col>
                <h2>Temperature and Humidy Test</h2>
            </Col>
        </Row>
        <Row className={'row-cols-1 row-cols-md-2'}>
          <Col>
              <h3>S/SM Series</h3>
              <p>Thermotron’s S/SM-Series Temperature and Temperature/Humidity Test Chambers provide high-performance over a wide range of applications. From prototyping to durability to product component screening tests, the S/SM-Series has been designed to meet quality standards while still offering flexibility, uniformity, and control accuracy for cost-effective testing.</p>
              <p>S/SM-Series Test Chambers are able to perform both high and low temperature tests. Many of these chambers have a temperature range of -70 to +180°C (-94 to +356°F). Temperature/Humidity models are equipped with a reliable, accurate, and efficient full range humidity system capable of simulating conditions from 10 to 98% RH. Hermetically sealed compressors provide moderate temperature change rates while allowing the chamber to consume less power than comparable chambers. Advanced features such as an electronic humidity sensor and product temperature control help reduce maintenance and test time.</p>
          </Col>
          <Col>
            <SSMSeriesImage/>
            {/* <img src="holder.js/700x520?random=yes&auto=yes"/> */}
          </Col>
        </Row>
        <Row className={'row-cols-1 row-cols-md-2'}>
          <Col>
            <div>
              <h3>SE Series</h3>
              <p>Thermotron’s SE-Series of environmental test chambers provides higher performance so our customers can experience higher productivity and more accurate results.</p>
              <p>An <b>optimized airflow</b> system provides conditioning in the center of the workspace where you need it most, providing more consistently repeatable test results. Improved gradient control produces tighter uniformity and greater accuracy.</p>
              <p>Compressor sizes range from 2 Hp to 20 Hp. As compressor sizes increase, temperature change rates can rise from 5°C/min to 20°/min. Higher stress levels identify product design flaws and process weaknesses more effectively.</p>
              <p>Most SE-Series chambers may also be equipped with Thermotron’s patented modular humidity system. This system may be purchased as a standard feature or easily added as a future upgrade. This humidity system is capable of providing 10% to 98% RH.</p>
            </div>
          </Col>
          <Col>
            <SESeriesImage/>
            {/* <img src="holder.js/700x520?random=yes&auto=yes"/> */}
          </Col>
        </Row>
        <Row className={'row-cols-1 row-cols-md-2'}>
          <Col>
            <div>
              <h3>Walk In Chamber</h3>
              <p>Whether you’re testing computers and copiers or automobiles and satellites, Thermotron can provide a Walk-In Environmental Test Chamber solution that works. Capable of testing large components, assemblies and finished products Thermotron’s Walk-In chambers are able to simulate a wide range of temperature and humidity environments. These chambers can be used for testing and controlled storage as well as a versatile laboratory environment for conducting test procedures in the telecommunications, defense, aerospace, pharmaceutical, automotive and electronics industries.</p>
              <p>Whatever your testing need, each of Thermotron’s Walk-in chambers consists of a self-contained conditioning module and a room constructed from interlocking panels or welded, insulated solid walls. Chamber construction, design and assembly are closely supervised. Operation verification tests are run on every chamber prior to shipment, ensuring a high-quality piece of testing equipment arrives at your site fully operational and ready to go.</p>
            </div>
          </Col>
          <Col>
            <WalkInSeriesImage/>
            {/* <img src="holder.js/700x520?random=yes&auto=yes"/> */}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
  
  export default TempHumid
